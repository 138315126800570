define("discourse/plugins/Small Caps/initializers/small-caps", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeSmallCaps(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/app/lib/plugin-api.js

    api.onToolbarCreate(toolbar => {
      toolbar.addButton({
        id: "smallcaps_button",
        group: "fontStyles",
        icon: "font",
        perform: e => e.applySurround('[smallcaps]', '[/smallcaps]', 'smallcaps_default_text'),
        label: "small_caps.composer.smallcaps_label",
        title: "small_caps.composer.smallcaps_title"
      });
    });
  }
  var _default = _exports.default = {
    name: "small-caps",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeSmallCaps);
    }
  };
});