define("discourse/plugins/Small Caps/discourse/templates/actions-show", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    controller-show.hbs id: {{model.id}}
  
  */
  {
    "id": "FLafWC/j",
    "block": "[[[1,\"controller-show.hbs id: \"],[1,[30,0,[\"model\",\"id\"]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/Small Caps/discourse/templates/actions-show.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[]]",
    "moduleName": "discourse/plugins/Small Caps/discourse/templates/actions-show.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});