define("discourse/plugins/Small Caps/discourse/routes/small-caps-actions-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "actions-index",
    model(params) {
      return this.store.findAll("action");
    },
    renderTemplate() {
      this.render("actions-index");
    }
  });
});