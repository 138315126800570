define("discourse/plugins/Small Caps/discourse-markdown/small-caps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  // https://meta.discourse.org/t/developers-guide-to-markdown-extensions/66023

  function setup(helper) {
    helper.allowList(['span.small-caps']);
    helper.registerPlugin(md => {
      md.inline.bbcode.ruler.push('small-caps', {
        tag: 'smallcaps',
        wrap: 'span.small-caps'
      });
    });
  }
});